import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import CustomModal from "../../../../../components/CustomModal";
import dayjs from "dayjs";
import { useDeliveryDateUpdateMutation } from "../../../../../redux/api/ordersApiSlice";
import { toast } from "react-toastify";
import { MoonLoader } from "react-spinners";

const commonBoxStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: "10px",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid #e0e0e0",
  width: "100%",
  "@media (max-width: 576px)": {
    overflowX: "auto",
  },
};

const commonTypographyStyles = {
  fontSize: "14px",
  fontWeight: 600,
};

const commonTypographyStylesTwo = {
  fontSize: "14px",
};

const boxStyles = {
  margin: "0 0 20px 0",
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: "10px",
  background: "#4dbb6c",
  padding: "10px",
  color: "#fff",
  borderRadius: "4px",
  width: "100%",
  "@media (max-width: 576px)": {
    overflowX: "auto",
  },
};

const labels = [
  "Name",
  "Image",
  "Req Quantity",
  "AVL Quantity",
  "Warehouse",
  "Batch No",
];

const ExpectedDeliveryModal = ({ modalOpen, setModalOpen, selectedItem }) => {
  const [deliveryDate, setDeliveryDate] = useState(null);

  useEffect(() => {
    if (selectedItem?.order?.delivery_date) {
      setDeliveryDate(selectedItem?.order?.delivery_date);
    }
  }, [selectedItem]);

  // todo: UPDATE ORDER DELIVERY DATE API BIND
  const [updateDate, { isLoading: updateDateLoading }] =
    useDeliveryDateUpdateMutation();

  const saveDeliveryDate = async () => {
    const date = dayjs(deliveryDate).format("YYYY-MM-DD");

    const payload = {
      delivery_date: date,
    };

    // console.log("payload", payload);
    // console.log("orderId", selectedItem?.order?.ordId);
    // return;

    try {
      const update = await updateDate({
        body: payload,
        orderId: selectedItem?.order?.ordId,
      });

      if (!update?.error) {
        toast.success("Delivery Date updated successfully!");
        setModalOpen(false);
      }
      if (update?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        sx={{
          width: "650px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 20px 0px 20px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Expected Delivery Date
            </Typography>
          </Box>
          <Box
            sx={{
              width: "24px",
              height: "24px",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(false)}
          >
            <IoClose />
          </Box>
        </Box>
        <Box sx={{ padding: "15px 20px" }}>
          {/* Table Head */}
          <Box sx={boxStyles}>
            {labels?.map((label) => (
              <Typography key={label} sx={commonTypographyStyles}>
                {label}
              </Typography>
            ))}
          </Box>
          {/* Table Body */}
          {selectedItem?.order?.orderproducts?.map((item) => {
            const condition =
              Number(item?.quantity) > Number(item?.stock?.productQuantity);

            return (
              <Box key={item.id} sx={commonBoxStyles}>
                <Typography sx={commonTypographyStylesTwo}>
                  {item?.product_meta?.commons?.en?.productName}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <img
                    src={item?.product_meta?.images[0]?.image}
                    alt="product"
                    style={{ width: "50px", height: "50px" }}
                  />
                </Box>
                <Typography
                  sx={{ ...commonTypographyStylesTwo, textAlign: "center" }}
                >
                  {Number(item?.quantity)}
                </Typography>
                <Typography
                  sx={{
                    ...commonTypographyStylesTwo,
                    color: condition ? "red" : "#000",
                    fontWeight: condition ? 600 : 400,
                    textAlign: "center",
                  }}
                >
                  {Number(item?.stock?.productQuantity)}
                </Typography>
                <Typography sx={commonTypographyStylesTwo}>
                  {item?.stock?.warehouse?.name} /{" "}
                  {item?.stock?.warehouse?.location}
                </Typography>
                <Typography
                  sx={{ ...commonTypographyStylesTwo, textAlign: "center" }}
                >
                  {item?.stock?.batchNumber}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box
          sx={{
            padding: "12px 20px 22px 20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "47%",
              }}
            >
              <CustomDatePicker
                value={deliveryDate}
                onChange={(value) => setDeliveryDate(value)}
                minDate={true}
                sx={{
                  width: "100%",
                }}
              />
            </Box>

            <Box
              sx={{
                width: "47%",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "40px",
                }}
                onClick={saveDeliveryDate}
              >
                {updateDateLoading ? (
                  <MoonLoader color="#000" size={20} />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ExpectedDeliveryModal;
