import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// Tags input component
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
// MUI Components Import
import {
  Container,
  Typography,
  Button,
  FormControl,
  Switch,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
// Loader Import
import { MoonLoader } from "react-spinners";
// Formik
import { Form, Formik } from "formik";
// React Toastify Imports
import { toast } from "react-toastify";
// Components import
import Header from "../../components/Header";
// Modals Import
import BrandModal from "../components/Modals/BrandModal";
import ModelModal from "../components/Modals/ModelModal";
import CategoryModal from "../components/Modals/CategoryModal";
import SubcategoriesModal from "../components/Modals/SubcategoriesModal";
import WarehouseModal from "../components/Modals/WarehouseModal";
import { baseUrl } from "../../../../constants/api";
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
import Errors from "../../../../components/Error";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import { SubHeading } from "../../../../components/Heading";
import SelectInput from "../../../../components/SelectInput";
import {
  addProductSchema,
  serviceSchema,
} from "./Validations/addProductSchema";
import { onKeyDown } from "../../../../utils";
import {
  renderDropdownField,
  renderInputField,
} from "./Helper/addProductHelper";
import Loader from "../../../Loaders/Loader";
import dayjs from "dayjs";
import { useGetEntitiesQuery } from "../../../../redux/api/categoryApiSlice";
import { IoAlertCircleOutline } from "react-icons/io5";

const discountTypeData = [
  {
    id: 0,
    value: "0",
    name: "None",
  },
  {
    id: 1,
    value: "1",
    name: "Percentage",
  },
  {
    id: 2,
    value: "2",
    name: "Amount",
  },
];

function AddProduct() {
  const token = window.localStorage.getItem("user-token");
  const location = useLocation();
  const { serviceScreen } = (location && location?.state) || {};

  const navigate = useNavigate();
  const { prod_id } = useParams();

  const headerData = {
    heading: prod_id ? "Update Product" : "Add a new Product",
    subheading: "",
  };

  const fileRef = useRef();

  const [formValues, setFormValues] = useState({
    productName: "",
    arabicProductName: "",
    productBarcode: "",
    brand: "",
    model: "",
    productCategory: "",
    productSubcategory: "",
    productQuantity: "",
    minQty: "",
    orderLimit: "",
    reorderLevel: "",
    purchasePrice: "",
    sellingPrice: "",
    onlinePrice: "",
    unit: "",
    code: "",
    glAccount: "",
    origin: "",
    videoURL: "",
    valuationRate: "",
    priceValidity: null,
    salesItem: false,
    purchaseItem: false,
    productType: "",
    description: "",
    description_ar: "",
    serviceTerms: "",
    serviceTerms_ar: "",
    pictures: [],
    weight: "",
    VHeight: "",
    VWidth: "",
    VLength: "",
  });
  const [psId, setPsid] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  // const [warehousesList, setWarehousesList] = useState([]);
  const [productTypesList, setProductTypesList] = useState([]);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [selectedDropdownType, setSelectedDropdownType] = useState(null);
  const [brandModalOpen, setBrandModalOpen] = useState(false);
  const [modelModalOpen, setModelModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [subcategoryModalOpen, setSubcategoryModalOpen] = useState(false);
  const [warehouseModalOpen, setWarehouseModalOpen] = useState(false);
  const [discountType, setDiscountType] = useState(0);
  const [discountValue, setDiscountValue] = useState("");
  const [tags, setTags] = useState([]);
  const [productWarehouses, setProductWarehouses] = useState([]);
  // const [getLoading, setGetLoading] = useState(false);
  const [productDetail, setProductDetail] = useState({});
  const [productTypeValue, setProductTypeValue] = useState("");
  const [subCategoryValue, setSubCategoryValue] = useState("");
  const [isService, setIsService] = useState(false);

  useEffect(() => {
    if (productTypeValue?.name === "Service") {
      // console.log("Service");
      setIsService(true);
    } else {
      setIsService(false);
    }
  }, [productTypeValue]);

  // todo: GET ENTITIES (WAREHOUSES) API CALL
  const { data: entitiesData, refetch: refetchEntities } = useGetEntitiesQuery(
    {}
  );

  useEffect(() => {
    if (entitiesData) {
      setBrandsList(entitiesData.brands);
      setModelsList(entitiesData.models);
      setCategoriesList(entitiesData.categories);
      setUnitsList(entitiesData.uoms);
      setProductTypesList(entitiesData.product_types);
    }
  }, [entitiesData]);

  // todo: GET SUB CATEGORIES DATA API CALL
  const fetchSubCategoriesData = async () => {
    if (!subCategoryValue?.id) return;

    try {
      const response = await axios.get(
        `${baseUrl}categories/${subCategoryValue?.id}/subcategories`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.data) {
        setSubcategoriesList(response.data);

        const productSubcategory = response.data.find(
          (category) => category.name === productDetail.productSubcategory
        );

        if (productSubcategory) {
          setFormValues({
            ...formValues,
            productSubcategory,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSubCategoriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryValue]);

  // todo: GET PRODUCT DATA API CALL
  const fetchProduct = async () => {
    if (!prod_id) return;

    try {
      const response = await axios.get(`${baseUrl}product/edit/${prod_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      if (response.data) {
        setProductDetail(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleAddClick = (dropdownType) => {
    setSelectedDropdownType(dropdownType);

    // Open the corresponding modal based on the dropdown type.
    switch (dropdownType) {
      case "Brand":
        setBrandModalOpen(true);
        break;
      case "Model":
        setModelModalOpen(true);
        break;
      case "Category":
        setCategoryModalOpen(true);
        break;
      case "Subcategory":
        setSubcategoryModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModals = () => {
    setBrandModalOpen(false);
    setModelModalOpen(false);
    setCategoryModalOpen(false);
    setSubcategoryModalOpen(false);
    setWarehouseModalOpen(false);
    setSelectedDropdownType(null);
  };

  useEffect(() => {
    if (productDetail) {
      const productType = productTypesList.find(
        (type) => type.value === productDetail.productType
      );

      const brand = brandsList.find(
        (brand) => brand.name === productDetail.brand
      );

      const model = modelsList.find(
        (model) => model.name === productDetail.model
      );

      const productCategory = categoriesList.find(
        (category) => category.name === productDetail.productCategory
      );

      if (productCategory) {
        setSubCategoryValue(productCategory);
      }

      const productSubcategory = subcategoriesList.find(
        (category) => category.name === productDetail.productSubcategory
      );

      const unit = unitsList.find(
        (unit) => unit.id === productDetail.productUnit
      );
      const productQuantity =
        productType?.name === "Service"
          ? ""
          : productDetail?.stock?.productQuantity;

      const minQty =
        isService && productDetail.minQty === "1.00"
          ? ""
          : productDetail.minQty || "";

      const orderLimit =
        productDetail.orderLimit === "0.00"
          ? ""
          : productDetail.orderLimit || "";

      const reorderLevel =
        productDetail.reorderLevel === "0.00"
          ? ""
          : productDetail.reorderLevel || "";

      const purchasePrice =
        productDetail.purchasePrice === "0.00"
          ? ""
          : productDetail.purchasePrice || "";

      const sellingPrice =
        productDetail.sellingPrice === "0.00"
          ? ""
          : productDetail.sellingPrice || "";

      const onlinePrice =
        productDetail.onlinePrice === "0.00"
          ? ""
          : productDetail.onlinePrice || "";

      const weight =
        productDetail.weight === "0.00" ? "" : productDetail.weight || "";

      const VHeight =
        productDetail.VHeight === "0.00" ? "" : productDetail.height || "";

      const VWidth =
        productDetail.VWidth === "0.00" ? "" : productDetail.width || "";

      const VLength =
        productDetail.VLength === "0.00" ? "" : productDetail.length || "";

      setFormValues({
        productName: productDetail?.commons?.en?.productName || "",
        arabicProductName: productDetail?.commons?.ar?.productName || "",
        productBarcode: productDetail.productBarcode || "",
        productType: productType || "",
        brand: brand || "",
        model: model || "",
        productCategory: productCategory || "",
        productSubcategory: productSubcategory || "",
        productQuantity,
        minQty,
        orderLimit,
        reorderLevel,
        purchasePrice,
        sellingPrice,
        onlinePrice,
        unit: unit || "",
        code: productDetail.code || "",
        glAccount: productDetail.glaccount || "",
        origin: productDetail.origin || "",
        videoURL: productDetail.video_url || "",
        valuationRate: productDetail.valuation_rate || "",
        priceValidity: productDetail.priceValidity || null,
        salesItem: productDetail.salesItem || false,
        purchaseItem: productDetail.purchaseItem || false,
        description: productDetail?.commons?.en?.description || "",
        description_ar: productDetail?.commons?.ar?.description || "",
        serviceTerms: productDetail?.commons?.en?.serviceTerms || "",
        serviceTerms_ar: productDetail?.commons?.ar?.serviceTerms || "",

        pictures: productDetail?.images?.map((img) => img.image) || [],

        weight,
        VHeight,
        VWidth,
        VLength,
      });

      const parseTags = productDetail?.tags?.map((tag) => tag.tag);
      setTags(parseTags === undefined ? [] : parseTags);

      const isSwitchOn =
        productDetail?.discount_type === 0 ? false : !prod_id ? false : true;
      setIsSwitchOn(isSwitchOn);

      const discountType = discountTypeData?.find(
        (type) => type.value === productDetail.discount_type + ""
      );

      if (discountType) {
        setDiscountType(discountType?.value);
      }

      setDiscountValue(productDetail.discount || "");
      setPsid(productDetail?.stock?.psId);
      setProductTypeValue(productType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productDetail,
    brandsList,
    categoriesList,
    modelsList,
    productTypesList,
    unitsList,
  ]);

  const AddDoctorHandler = async (data) => {
    const commons = {
      ar: {
        productName: data.arabicProductName,
        description: data.description_ar,
        serviceTerms: data.serviceTerms_ar,
      },
      en: {
        productName: data.productName,
        description: data.description,
        serviceTerms: data.serviceTerms,
      },
    };

    const stock = {
      productQuantity: isService ? 999999 : data.productQuantity.replace(/,/g, ""),
    };

    const updateStock = {
      psId,
      productQuantity: isService ? 999999 : data.productQuantity.replace(/,/g, ""),
    };

    const prodFormData = new FormData();
    if (prod_id) {
      prodFormData.append("psId", psId);
    }

    const sendStock = prod_id ? updateStock : stock;

    const minQty = isService ? 1 : data.minQty;

    prodFormData.append("productStatus", 1);
    prodFormData.append("commons", JSON.stringify(commons) || {});
    prodFormData.append("stock", JSON.stringify(sendStock) || {});
    prodFormData.append("productBarcode", data.productBarcode || "");
    prodFormData.append("brand", data.brand.id || "");
    prodFormData.append("productType", data.productType.value || "");
    prodFormData.append("model", data.model.id || "");
    prodFormData.append("productCategory", data.productCategory.id || "");
    prodFormData.append("productSubcategory", data.productSubcategory.id || "");
    prodFormData.append("minQty", minQty);
    prodFormData.append("orderLimit", data.orderLimit || "");
    prodFormData.append("reorderLevel", data.reorderLevel || "");
    prodFormData.append("purchasePrice", data.purchasePrice || "");
    prodFormData.append("sellingPrice", data.sellingPrice || "");
    prodFormData.append("onlinePrice", data.onlinePrice || "");
    prodFormData.append("productUnit", data.unit.id || "");
    prodFormData.append("code", data.code || "");
    prodFormData.append("glaccount", data.glAccount || "");
    prodFormData.append("origin", data.origin || "");
    prodFormData.append("video_url", data.videoURL || "");

    const tagsObject = tags?.map((tag) => {
      return {
        tag,
      };
    });

    prodFormData.append("tags", JSON.stringify(tagsObject));
    prodFormData.append("weight", data.weight);
    prodFormData.append("height", data.VHeight);
    prodFormData.append("width", data.VWidth);
    prodFormData.append("length", data.VLength);
    prodFormData.append("volume", data.VHeight * data.VWidth * data.VLength);

    prodFormData.append("productWarehouses", JSON.stringify(productWarehouses));

    if (typeof data.priceValidity === "string") {
      prodFormData.append("priceValidity", data.priceValidity);
    } else if (data.priceValidity) {
      const date = new Date(data.priceValidity);

      // Adjust for timezone offset
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );

      const formattedDate = localDate.toISOString().split("T")[0];
      prodFormData.append("priceValidity", formattedDate);
    }

    prodFormData.append("discount_type", discountType || "");
    prodFormData.append("discount", discountValue || "");

    // todo: Add Case
    if (!prod_id) {
      for (let i = 0; i < data.pictures.length; i++) {
        prodFormData.append("productImages[]", data.pictures[i]);
      }
    }

    // todo: Update Case
    if (prod_id) {
      for (let i = 0; i < data.pictures.length; i++) {
        if (productDetail?.images[i] && "image" in productDetail?.images[i]) {
          prodFormData.append(
            "productImagesIds[]",
            productDetail?.images[i].piId
          );
        } else {
          prodFormData.append("productImages[]", data.pictures[i]);
        }
      }
    }

    // todo: UPDATE CASE
    if (prod_id) {
      try {
        setLoading(true);

        const response = await axios.put(
          `${baseUrl}product/${prod_id}`,
          prodFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        const data = response.data;
        const message = isService ? "Service" : "Product";

        if (data) {
          setLoading(false);
          toast.success(`${message} updated successfully`);
          localStorage.setItem("products", "products");
          navigate("/dashboard/products-list");
        }
      } catch (err) {
        if (err.response) {
          if (err.response.data) {
            const errorMessages = [];
            for (const key in err.response.data) {
              if (Array.isArray(err.response.data[key])) {
                errorMessages.push(...err.response.data[key]);
                toast.error(`${key} ${err.response.data[key][0]}`);
              }
            }
            if (errorMessages.length > 0) {
            } else {
              toast.error("Failed to update product!");
            }
          } else {
            toast.error(
              err.response.data.message || "Failed to update product!"
            );
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }

      return;
    }

    try {
      setLoading(true);

      const response = await axios.post(
        `${baseUrl}product/create`,
        prodFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      const data = response.data;
      const message = isService ? "Service" : "Product";

      if (data) {
        setLoading(false);
        toast.success(`${message} added successfully`);
        localStorage.setItem("products", "products");
        navigate("/dashboard/products-list");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data) {
          const errorMessages = [];
          for (const key in err.response.data) {
            if (Array.isArray(err.response.data[key])) {
              errorMessages.push(...err.response.data[key]);
              toast.error(`${key} ${err.response.data[key][0]}`);
            }
          }
          if (errorMessages.length > 0) {
          } else {
            toast.error("Failed to update product!");
          }
        } else {
          toast.error(err.response.data.message || "Failed to update product!");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Header data={headerData} />

        {/* {isLoading && <Loader />} */}

        <Box
          sx={{
            padding: "20px 20px 25px 20px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            marginTop: "20px",
          }}
        >
          <Box>
            <Formik
              initialValues={formValues}
              onSubmit={(values) => {
                AddDoctorHandler(values);
              }}
              validationSchema={isService ? serviceSchema : addProductSchema}
              enableReinitialize
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                } = props;

                // console.log("errors", errors);
                // console.log("values", values);

                return (
                  <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {renderInputField(
                        "Product Name (Eng)",
                        "Product Name (Eng)",
                        "text",
                        "productName",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Product Name (Arabic)",
                        "Product Name (Arabic)",
                        "text",
                        "arabicProductName",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Product Bar Code",
                        "Product Bar Code",
                        "text",
                        "productBarcode",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}

                      <Tooltip
                        title={
                          prod_id ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IoAlertCircleOutline
                                style={{
                                  color: "red",
                                  marginRight: "8px",
                                  fontSize: "40px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <div>
                                Product Type cannot be editable once selected
                              </div>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#fff",
                              boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              padding: "10px",
                              maxWidth: "215px",
                              fontSize: "0.8rem",
                              color: "#000",
                              "& .MuiTooltip-arrow": {
                                color: "#fff",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Product Type
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </SubHeading>

                          <SelectInput
                            name="productType"
                            styles={{ width: "100%" }}
                            value={values.productType || ""}
                            borderRadius="4px"
                            readOnly={prod_id}
                            onChange={(e) => {
                              props.handleChange(e);
                              setProductTypeValue(e.target.value);

                              if (e.target.value.name === "Service") {
                                setFieldValue("productQuantity", "");
                                setFieldValue("minQty", "");
                                setFieldValue("orderLimit", "");
                                setFieldValue("reorderLevel", "");
                              }
                            }}
                            data={productTypesList}
                            onBlur={handleBlur}
                            error={
                              errors.productType && touched.productType
                                ? true
                                : false
                            }
                            label="Select Type"
                            options={productTypesList?.map((ser) => {
                              return {
                                ...ser,
                                id: ser.value,
                                value: ser,
                                label: ser.name,
                              };
                            })}
                          >
                            {errors.productType && (
                              <Errors sx={{ paddingLeft: "0" }}>
                                {errors.productType && touched.productType
                                  ? errors.productType
                                  : ""}
                              </Errors>
                            )}
                          </SelectInput>
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {renderDropdownField(
                        "Brand",
                        "brand",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        brandsList,
                        handleAddClick
                      )}
                      {renderDropdownField(
                        "Model",
                        "model",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        modelsList,
                        handleAddClick
                      )}

                      <Box sx={{ width: "100%", height: "85px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                          }}
                        >
                          <SubHeading
                            sx={{
                              fontSize: "12px",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            Category
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </SubHeading>
                          {handleAddClick && (
                            <Button
                              sx={{
                                fontSize: "12px",
                                padding: "2px 4px",
                                border: "none",
                                borderRadius: "4px",
                                backgroundColor: "#2196f3",
                                width: "fit-content",
                                color: "#fff",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "#1976d2",
                                },
                              }}
                              onClick={() => handleAddClick("Category")}
                            >
                              Add New
                            </Button>
                          )}
                        </Box>

                        <SelectInput
                          name="productCategory"
                          styles={{ width: "100%" }}
                          value={values.productCategory || ""}
                          borderRadius="4px"
                          onChange={(e) => {
                            props.handleChange(e);
                            setSubCategoryValue(e.target.value);
                          }}
                          data={categoriesList}
                          onBlur={handleBlur}
                          error={
                            errors.productCategory && touched.productCategory
                              ? true
                              : false
                          }
                          label="Select Category"
                          options={categoriesList?.map((option) => ({
                            ...option,
                            id: option.value,
                            value: option,
                            label: `${option.name} - ${option.arb_name}`,
                          }))}
                        >
                          {errors.productCategory && (
                            <Errors sx={{ paddingLeft: "0" }}>
                              {errors.productCategory && touched.productCategory
                                ? errors.productCategory
                                : ""}
                            </Errors>
                          )}
                        </SelectInput>
                      </Box>

                      {renderDropdownField(
                        "Subcategory",
                        "productSubcategory",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        subcategoriesList,
                        handleAddClick
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: errors?.productCategory
                          ? "20px"
                          : errors?.productSubcategory
                          ? "20px"
                          : "15px",
                      }}
                    >
                      <Tooltip
                        title={
                          isService ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IoAlertCircleOutline
                                style={{
                                  color: "red",
                                  marginRight: "8px",
                                  fontSize: "40px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <div>
                                It's not writeable when the product type is
                                Service.
                              </div>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#fff",
                              boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              padding: "10px",
                              maxWidth: "215px",
                              fontSize: "0.8rem",
                              color: "#000",
                              "& .MuiTooltip-arrow": {
                                color: "#fff",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {renderInputField(
                            "Product Quantity",
                            "Product Quantity",
                            "text",
                            "productQuantity",
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            // todo: DISABLE FOR SERVICES
                            false,
                            null,
                            isService
                          )}
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          isService ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IoAlertCircleOutline
                                style={{
                                  color: "red",
                                  marginRight: "8px",
                                  fontSize: "40px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <div>
                                It's not writeable when the product type is
                                Service.
                              </div>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#fff",
                              boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              padding: "12px",
                              maxWidth: "215px",
                              fontSize: "0.8rem",
                              color: "#000",
                              "& .MuiTooltip-arrow": {
                                color: "#fff",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {renderInputField(
                            "Min Qty",
                            "Min Qty",
                            "text",
                            "minQty",
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur, // todo: DISABLE FOR SERVICES
                            false,
                            null,
                            isService
                          )}
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          isService ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IoAlertCircleOutline
                                style={{
                                  color: "red",
                                  marginRight: "8px",
                                  fontSize: "40px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <div>
                                It's not writeable when the product type is
                                Service.
                              </div>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#fff",
                              boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              padding: "12px",
                              maxWidth: "215px",
                              fontSize: "0.8rem",
                              color: "#000",
                              "& .MuiTooltip-arrow": {
                                color: "#fff",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {renderInputField(
                            "Order Limit",
                            "Order Limit",
                            "text",
                            "orderLimit",
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            // todo: DISABLE FOR SERVICES
                            false,
                            null,
                            isService
                          )}
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          isService ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IoAlertCircleOutline
                                style={{
                                  color: "red",
                                  marginRight: "8px",
                                  fontSize: "40px",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />
                              <div>
                                It's not writeable when the product type is
                                Service.
                              </div>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#fff",
                              boxShadow: "0 0 25px rgba(0, 0, 0, 0.1)",
                              borderRadius: "5px",
                              padding: "12px",
                              maxWidth: "215px",
                              fontSize: "0.8rem",
                              color: "#000",
                              "& .MuiTooltip-arrow": {
                                color: "#fff",
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {renderInputField(
                            "Reorder Level",
                            "Reorder Level",
                            "text",
                            "reorderLevel",
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            // todo: DISABLE FOR SERVICES
                            false,
                            null,
                            isService
                          )}
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: errors?.productQuantity ? "10px" : "5px",
                      }}
                    >
                      {renderInputField(
                        "Purchase Price",
                        "Purchase Price",
                        "text",
                        "purchasePrice",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Selling Price",
                        "Selling Price",
                        "text",
                        "sellingPrice",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Online Price",
                        "Online Price",
                        "text",
                        "onlinePrice",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderDropdownField(
                        "Unit",
                        "unit",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        unitsList
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: errors?.onlinePrice ? "10px" : "5px",
                      }}
                    >
                      {renderInputField(
                        "Code",
                        "Code",
                        "text",
                        "code",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}

                      {renderInputField(
                        "GL Account",
                        "GL Account",
                        "text",
                        "glAccount",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}

                      {renderInputField(
                        "Origin",
                        "Origin",
                        "text",
                        "origin",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}

                      {renderInputField(
                        "Video URL",
                        "Video URL",
                        "text",
                        "videoURL",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "5px",
                      }}
                    >
                      {renderInputField(
                        "Valuation Rate",
                        "Valuation Rate",
                        "number",
                        "valuationRate",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}

                      <Box sx={{ width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Price Validity
                        </SubHeading>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          sx={{
                            paddingTop: "4px",
                            width: "100%",
                          }}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            valueFormat="YYYY-MM-DD"
                            value={
                              values.priceValidity
                                ? dayjs(values.priceValidity)
                                : null
                            }
                            onChange={(value) => {
                              setFieldValue(
                                "priceValidity",
                                value ? value : null
                              );
                            }}
                            minDate={dayjs(new Date())}
                            slotProps={{
                              textField: {
                                sx: {
                                  height: "50px",
                                  width: "100%",
                                  "& .MuiInputBase-root": {
                                    height: "50px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                },
                                error: false,
                              },
                            }}
                            sx={{
                              background: "#fff",
                            }}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Label
                            sx={{
                              padding: "0",
                              fontSize: "13px",
                            }}
                          >
                            Sales Item
                          </Label>
                          <Checkbox
                            type="checkbox"
                            name="salesItem"
                            value={values.salesItem}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Label
                            sx={{
                              padding: "0",
                              fontSize: "13px",
                            }}
                          >
                            Purchase Item
                          </Label>
                          <Checkbox
                            type="checkbox"
                            // checked={purchaseItem}
                            // onChange={(e) => isPurchaseItem(e.target.checked)}
                            name="purchaseItem"
                            value={values.purchaseItem}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>

                      <Box sx={{ width: "100%" }}></Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "5px",
                      }}
                    >
                      {renderInputField(
                        "Description (Eng)",
                        "Description",
                        "text",
                        "description",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        true,
                        2
                      )}

                      {renderInputField(
                        "Description (Arabic)",
                        "Description (Arabic)",
                        "text",
                        "description_ar",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        true,
                        2
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: errors?.description
                          ? "5px"
                          : errors?.description_ar
                          ? "5px"
                          : "20px",
                      }}
                    >
                      {renderInputField(
                        "Service Term (Eng)",
                        "Service Term (Eng)",
                        "text",
                        "serviceTerms",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        true,
                        2
                      )}

                      {renderInputField(
                        "Service Term (Arabic)",
                        "Service Term (Arabic)",
                        "text",
                        "serviceTerms_ar",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        true,
                        2
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <input
                            onChange={(e) => {
                              if (e.target.files) {
                                const newFiles = Array.from(e.target.files);
                                setFieldValue("pictures", [
                                  ...values.pictures,
                                  ...newFiles,
                                ]);
                                e.target.value = null; // Reset the file input
                              }
                            }}
                            hidden
                            ref={fileRef}
                            type="file"
                            accept="image/*"
                            multiple
                          />

                          <Box
                            onClick={() => fileRef.current.click()}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "130px",
                              justifyContent: "center",
                            }}
                          >
                            <AddPhoto sx={{ fontSize: "25px" }} />
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "12px",
                                color: "#2A3F54",
                              }}
                            >
                              Add Images
                              <span
                                style={{
                                  color: "red",
                                }}
                              >
                                *
                              </span>
                            </Typography>
                            {errors.pictures && touched.pictures && (
                              <Errors sx={{ paddingLeft: "0" }}>
                                {errors.pictures}
                              </Errors>
                            )}
                          </Box>

                          {/* Showing images */}
                          {values?.pictures?.map((picture, index) => (
                            <Box
                              key={index}
                              sx={{ position: "relative", margin: "10px" }}
                            >
                              <img
                                src={
                                  typeof picture === "string"
                                    ? picture
                                    : URL.createObjectURL(picture)
                                }
                                alt={`user-${index}`}
                                width={100}
                                height={90}
                                style={{
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                              />
                              <CancelIcon
                                sx={{
                                  fontSize: "20px",
                                  position: "absolute",
                                  top: "-10px",
                                  right: "-9px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const newPictures = values.pictures.filter(
                                    (_, i) => i !== index
                                  );
                                  setFieldValue("pictures", newPictures);
                                  // also remove image from productDetail.images
                                  const newImages = productDetail.images.filter(
                                    (_, i) => i !== index
                                  );
                                  setProductDetail({
                                    ...productDetail,
                                    images: newImages,
                                  });
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "50%",
                        }}
                        className="react-tags-input"
                      >
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "12px" }}
                        >
                          Tags
                        </Typography>
                        <TagsInput
                          value={tags}
                          onChange={handleTagsChange}
                          inputProps={{
                            placeholder: "Press Enter",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "20px",
                      }}
                    >
                      {renderInputField(
                        "Weight (kg)",
                        "Weight (kg)",
                        "text",
                        "weight",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Height (cm)",
                        "Height (cm)",
                        "text",
                        "VHeight",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Width (cm)",
                        "Width (cm)",
                        "text",
                        "VWidth",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                      {renderInputField(
                        "Length (cm)",
                        "Length (cm)",
                        "text",
                        "VLength",
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ height: "85px", width: "100%" }}>
                        <SubHeading
                          sx={{ marginBottom: "5px", fontSize: "12px" }}
                        >
                          Dimensions (H*W*L)
                        </SubHeading>
                        <PrimaryInput
                          readOnly={true}
                          type="text"
                          label=""
                          name="volume"
                          placeholder="Dimensions (H*W*L)"
                          value={
                            values.VHeight * values.VWidth * values.VLength
                          }
                          borderRadius="4px"
                        />
                      </Box>
                      <Box sx={{ width: "100%" }}></Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                        marginTop: "10px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "20px",
                              alignItems: "center",
                            }}
                          >
                            <Heading>Discount</Heading>
                            <Switch
                              size="medium"
                              color="secondary"
                              checked={isSwitchOn}
                              onChange={() => setIsSwitchOn(!isSwitchOn)}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ width: "100%" }}></Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        {isSwitchOn && (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "20px",
                              marginTop: "10px",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Label>Discount Type</Label>
                              <FormControl
                                size="small"
                                sx={{
                                  background: "#fff",
                                  borderRadius: "12px",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "12px",
                                  height: "50px",
                                  "& .MuiInputBase-root": {
                                    height: "50px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <SelectInput
                                  name="discountType"
                                  styles={{ width: "100%" }}
                                  borderRadius="4px"
                                  height="41px"
                                  value={discountType}
                                  onChange={(e) => {
                                    setDiscountType(e.target.value);
                                  }}
                                  data={discountTypeData}
                                  label="Select Discount Type"
                                  options={discountTypeData?.map((option) => ({
                                    id: option.id,
                                    value: option.value,
                                    label: option.name,
                                  }))}
                                ></SelectInput>
                              </FormControl>
                            </Box>

                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              <SubHeading
                                sx={{ marginBottom: "5px", fontSize: "12px" }}
                              >
                                Discount Amount
                              </SubHeading>
                              <PrimaryInput
                                type="text"
                                label=""
                                name="Discount Amount"
                                placeholder="Discount Amount"
                                value={discountValue}
                                onChange={(e) =>
                                  setDiscountValue(e.target.value)
                                }
                                borderRadius="4px"
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ width: "100%" }}></Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "15px",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                          height: "40px",
                          width: "150px",
                          mt: "23px",
                          borderRadius: "5px",
                        }}
                        onClick={() => navigate("/dashboard/products-list")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{
                          height: "40px",
                          width: "150px",
                          mt: "23px",
                          borderRadius: "5px",
                        }}
                      >
                        {loading ? (
                          <>
                            <MoonLoader color="#fff" size={20} />
                          </>
                        ) : prod_id ? (
                          "Update"
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>

        {/* <ProductWarehouses
            warehousesList={warehousesList}
            onUpdateWarehouses={handleWarehouseChanges}
            onAddClick={() => setWarehouseModalOpen(true)}
          /> */}
        <WarehouseModal
          open={warehouseModalOpen}
          onClose={handleCloseModals}
          data={refetchEntities}
        />

        <BrandModal
          open={brandModalOpen}
          setOpen={setBrandModalOpen}
          fetchEntities={refetchEntities}
        />
        <ModelModal
          open={modelModalOpen}
          setOpen={setModelModalOpen}
          fetchEntities={refetchEntities}
        />
        <CategoryModal
          open={categoryModalOpen}
          setOpen={setCategoryModalOpen}
          fetchEntities={refetchEntities}
        />
        <SubcategoriesModal
          open={subcategoryModalOpen}
          setOpen={setSubcategoryModalOpen}
          list={categoriesList}
          fetchEntities={refetchEntities}
        />

        {/* <ProductVariants variantsList={variantsList} onUpdateVariants={handleVariantChanges} /> */}
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
  paddingBottom: "5px",
});

const Heading = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "600",
}));

export default AddProduct;
