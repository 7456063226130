import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import axios from 'axios';

// MUI Components Import
import { Modal, Box, Typography, TextField, Button } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Formik & Yup Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { baseUrl } from '../../../../../constants/api';

const validationSchema = Yup.object({
    engName: Yup.string().required('English Name is required'),
    arabicName: Yup.string().required('Arabic Name is required'),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:"8px",
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    gap: "12px"
};

function BrandModal({ open, setOpen, fetchEntities }) {

    const token = window.localStorage.getItem("user-token");

    const [saving, setSaving] = useState(false)

    const formik = useFormik({
        initialValues: {
            engName: '',
            arabicName: '',
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            handleSave(formik.values);
        },
    });

    const handleSave= (formValues) => {
        setSaving(true)
        axios.post(`${baseUrl}brand/create`,
        {
            name: formValues.engName,
            arb_name: formValues.arabicName,
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Brand added successfully!");
            setTimeout(() => {
                setSaving(false);
            }, 2000);
            formik.resetForm();
            fetchEntities();
            setOpen(false);
        })
        .catch((err) => {
            if (err.response) {
                toast.error(err.response.data.message);
            }
            setSaving(false);
        })
    }
    
    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Heading>Add a new Brand</Heading>
                    <Input mt={3} placeholder='Brand Name English' 
                        {...formik.getFieldProps('engName')}
                        error={formik.touched.engName && Boolean(formik.errors.engName)}
                        helperText={formik.touched.engName && formik.errors.engName}
                    />
                    <Input mt={3} placeholder='Brand Name Arabic' 
                        {...formik.getFieldProps('arabicName')}
                        error={formik.touched.arabicName && Boolean(formik.errors.arabicName)}
                        helperText={formik.touched.arabicName && formik.errors.arabicName}
                    />
                    <SaveBTN onClick={() => formik.handleSubmit()}>
                        {saving ? (
                            <>                                    
                                <MoonLoader color="#fff" size={20} />
                            </>
                            ) : (
                            "Save"
                        )}
                    </SaveBTN>
                </Box>
            </Modal>
        </>
    )
}

const Heading = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "600"
}))

const Input = styled(TextField)(({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "5px"
}))

const SaveBTN = styled(Button)(() => ({
    width: '150px', 
    height: '40px', 
    margin: '0 auto', 
    background: "#009444", 
    color: "#fff", 
    borderRadius: '5px',
    "&:hover": {
        backgroundColor: "#009444"
    }
}))

export default BrandModal