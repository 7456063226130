// MUI Imports
import { alpha, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import {
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { MoonLoader } from "react-spinners";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#eff1f7",
    color: "#49454f",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MUITable = ({
  tableHead,
  children,
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  background,
  // For Checkbox
  selected,
  setSelected,
  bulkDelete,
  loading,
}) => {
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      {/* For Checkbox */}
      {selected?.length > 0 && (
        <Toolbar
          sx={{
            ...(selected?.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            minHeight: "60px !important",
          }}
        >
          {selected?.length > 0 && (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected?.length} selected
            </Typography>
          )}

          {selected?.length > 0 && (
            <>
              {loading ? (
                <MoonLoader color="#000" size={20} />
              ) : (
                <Tooltip title="Delete">
                  <IconButton onClick={() => bulkDelete(selected)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Toolbar>
      )}

      <TableContainer
        sx={{
          height: "auto",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "8px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#EBEBEB",
            borderRadius: "8px",
          },
        }}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* For Checkbox */}
              {selected && (
                <StyledTableCell
                  sx={{
                    minWidth: "160px",
                    backgroundColor: background ? background : "#eff1f7",
                  }}
                >
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected?.length > 0 && selected?.length < rows?.length
                    }
                    checked={
                      rows?.length > 0 && selected?.length === rows?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    sx={{
                      padding: 0,
                      "& .MuiButtonBase-root": {
                        padding: 0,
                      },
                    }}
                  />
                </StyledTableCell>
              )}
              {tableHead?.map((header, index) => (
                <StyledTableCell
                  align="left"
                  key={index}
                  sx={{
                    minWidth: "160px",
                    backgroundColor: background ? background : "#eff1f7",
                  }}
                >
                  {header}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 5 && (
        <Box
          sx={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Paper>
  );
};

export default MUITable;
