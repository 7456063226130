import { Box, Button, Container, Grid, styled, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'

// Icons Import
import AddPhoto from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import { baseUrl } from '../../../../constants/api';
import Header from "../../components/Header";

const headerData = {
    heading: "Manage Partner Brands",
    subheading: "",
};

function BrandPartners() {

    const token = window.localStorage.getItem("user-token");

    const imgUpload = useRef();

    const selectFiles = () => {
        imgUpload.current.click();
    };

    const [uploading, setUploading] = useState(false)
    const [logos, setLogos] = useState([]);
    const [files, setFiles] = useState([]);
    const [previewFiles, setPreviewFiles] = useState([])

    const handleImageUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        const newPreviewFiles = [...previewFiles, ...selectedFiles?.map(file => URL.createObjectURL(file))];
        setPreviewFiles(newPreviewFiles);
    };

    const handleRemoveImage = (indexToRemove) => {
        setPreviewFiles((prevPreviewImages) => prevPreviewImages.filter((_, index) => index !== indexToRemove));
    };

    const uploadLogos = () => {
        setUploading(true)

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("images[]", files[i]);
        }

        axios.post(`${baseUrl}brand_logo/create`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Logos Added successfully!");
            setTimeout(() => {
                setUploading(false)
            }, 2000);
            setFiles([]);
            setPreviewFiles([])
            fetchLogos()
        })
        .catch((err) => {
            toast.error("Failed to add Logos!");
            setUploading(false)
        })
    }

    const fetchLogos = () => {
        axios.get(`${baseUrl}brand_logos`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            setLogos(response.data)
        })
        .catch((err) => {
            console.log("Error", err)
        })
    }

    const deleteLogo = (id) => {
        axios.delete(`${baseUrl}brand_logo/delete/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then(() => {
            toast.success("Logo has been deleted!");
            fetchLogos()
        })
        .catch((err) => {
            console.log("Error", err)
        })
    }

    useEffect(() => {
        fetchLogos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <>
            <Wrapper maxWidth={false}>
                <Header data={headerData} />
                <BoxWrapper container my={2}>
                    <Uploader item md={1} onClick={() => selectFiles()}>
                        <input type="file" accept="image/*" multiple ref={imgUpload} style={{ display: 'none' }} onChange={handleImageUpload} id="imageInput" />
                        <AddPhoto sx={{ fontSize: '25px' }} />
                        <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2A3F54' }}>
                            Add Images
                        </Typography>
                    </Uploader>
                    <Grid item md={12}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {previewFiles?.map((file, index) => (
                                <>
                                    <ImgDisplay key={index} sx={{ background: `url(${file}) center/cover no-repeat` }} />
                                    <CancelIcon sx={{ fontSize: "20px", position: "relative", right: "20px", cursor: "pointer" }}
                                        onClick={() => handleRemoveImage(index)}
                                    />
                                </>
                            ))}
                        </div>
                    </Grid>
                    <Box mt={'50px'} gap={'30px'} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                        {files.length > 0 && (
                            <Button color="secondary" variant="contained"
                                style={{ textTransform: "none", width: '100px' }}
                                onClick={() => uploadLogos()}
                            >
                                {uploading ? (
                                    <>
                                        <MoonLoader color="#fff" size={20} />
                                    </>
                                ) : (
                                    "Upload"
                                )}
                            </Button>
                        )}
                    </Box>
                    <Grid container my={5}>
                        {logos.length > 0 ?
                            <>
                                <Grid item md={12}>
                                    <Typography sx={{ fontWeight: '600', fontSize: '18px', color: '#2A3F54' }}>
                                        Uploaded Logos
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: "20px" }}>
                                        {logos?.map((img, index) => (
                                            <>
                                                <ImgDisplay key={index} sx={{ background: `url(${img.image}) center/cover no-repeat` }}>
                                                    <Tooltip title="Delete" placement='top' >
                                                        <DeleteIcon sx={{ fontSize: "26px", position: "relative", left: "94%", top: "5px", cursor: "pointer", background: "#fff", color: "red", borderRadius: "5px" }}
                                                            onClick={() => deleteLogo(img.blgID)}
                                                        />
                                                    </Tooltip>
                                                </ImgDisplay>
                                            </>
                                        ))}
                                    </div>
                                </Grid>
                            </>
                            :
                            null
                        }
                    </Grid>
                </BoxWrapper>
            </Wrapper>
        </>
    )
}

// Styled Components
const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: "1rem",
    height: "calc(100vh - 60px)",
    overflow: "auto",
}));

const BoxWrapper = styled(Grid)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600",
}));

const Uploader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    height: "90px",
    cursor: "pointer"
}))

const ImgDisplay = styled(Box)(() => ({
    width: '120px',
    height: '120px',
    backgroundColor: '#fff',
    margin: '10px',
    borderRadius: '10px',
}))

export default BrandPartners