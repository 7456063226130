import React, { useEffect, useState } from "react";
import CustomModal from "../../../../../components/CustomModal";
import { Box, Button, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5";
import PrimaryInput from "../../../../../components/PrimaryInput/PrimaryInput";
import {
  useGetProductsByWareHouseQuery,
  useGetWareHousesByProductQuery,
} from "../../../../../redux/api/wareHouseApiSlice";
import { MoonLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { FaAngleLeft } from "react-icons/fa";
import { skipToken } from "@reduxjs/toolkit/query";

const ProductWareHouseModal = ({
  modalOpen,
  setModalOpen,
  selectedProduct,
  setFormValues,
  formValues,
  setEditMode,
}) => {
  const navigate = useNavigate();

  // states
  const [wareHouses, setWareHouses] = useState([]);
  const [requestedItems, setRequestedItems] = useState([]);
  // todo: FOR GETTING WAREHOUSES BY PRODUCT
  const [apiIds, setApiIds] = useState({
    id: skipToken,
    stock: skipToken,
  });
  const [getWareHouseId, setGetWareHouseId] = useState(skipToken);

  useEffect(() => {
    if (selectedProduct) {
      setApiIds({
        id: selectedProduct?.product?.prodId,
        stock: selectedProduct?.stock,
      });
      setGetWareHouseId(selectedProduct?.product?.id);
    }
  }, [selectedProduct]);

  // todo: GET PRODUCTS FORM WAREHOUSE API CALL
  // const {
  //   data: getProductsByWareHouseData,
  //   isLoading: getProductsByWareHouseDataLoading,
  //   refetch: refetchGetProductsByWareHouseData,
  // } = useGetProductsByWareHouseQuery({
  //   id: getWareHouseId,
  // });

  const getRequestedQuantitySum = (items) => {
    return items.reduce((sum, item) => sum + Number(item.requestedQuantity), 0);
  };

  // useEffect(() => {
  //   if (getProductsByWareHouseData) {
  //     const updatedFormValues = {
  //       ...formValues,
  //       products: formValues.products.map((product) =>
  //         product.id === selectedProduct?.id
  //           ? {
  //               ...product,
  //               requestedItem: {
  //                 product: product.id,
  //                 requested_items: getProductsByWareHouseData?.requested_items,
  //                 totalRequestedQuantity: getRequestedQuantitySum(
  //                   getProductsByWareHouseData?.requested_items
  //                 ),
  //               },
  //             }
  //           : product
  //       ),
  //     };

  //     setEditMode(true);

  //     // console.log("pppppp", updatedFormValues);
  //   }
  // }, [getProductsByWareHouseData]);

  // console.log("getProductsByWareHouseData", getProductsByWareHouseData);

  // todo: GET WAREHOUSES BY PRODUCT
  const {
    data: getWareHousesByProduct,
    isLoading: getWareHousesByProductLoading,
    refetch: getWareHousesByProductRefetch,
  } = useGetWareHousesByProductQuery({
    id: apiIds?.id,
    stock: apiIds?.stock,
  });

  useEffect(() => {
    if (modalOpen) {
      getWareHousesByProductRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    if (getWareHousesByProduct) {
      setWareHouses(getWareHousesByProduct);

      const savedFormValues = JSON.parse(
        localStorage.getItem("formValues") || "{}"
      );

      const selectedProductData = savedFormValues.products?.find(
        (product) => product.id === selectedProduct?.id
      );

      const initialRequestedItems = getWareHousesByProduct?.map((item) => {
        const existingItem =
          selectedProductData?.requestedItem?.requested_items?.find(
            (ri) => ri.id === item.id
          );
        return {
          warehouse: item.warehouse.id,
          requestedQuantity: existingItem
            ? existingItem.requestedQuantity
            : "0",
          id: item.id,
        };
      });

      setRequestedItems(initialRequestedItems);

      const totalRequestedQuantity = getRequestedQuantitySum(
        initialRequestedItems
      );

      const updatedFormValues = {
        ...formValues,
        products: formValues?.products?.map((product) =>
          product.id === selectedProduct?.id
            ? {
                ...product,
                requestedItem: {
                  product: product.id,
                  requested_items: initialRequestedItems,
                  totalRequestedQuantity,
                },
              }
            : product
        ),
      };

      setFormValues(updatedFormValues);
      // localStorage.setItem("formValues", JSON.stringify(updatedFormValues));
    }
  }, [getWareHousesByProduct]);

  const handleRequestedQuantityChange = (index, value) => {
    const updatedRequestedItems = requestedItems?.map((item, idx) =>
      idx === index ? { ...item, requestedQuantity: value } : item
    );
    setRequestedItems(updatedRequestedItems);

    const totalRequestedQuantity = getRequestedQuantitySum(
      updatedRequestedItems
    );

    // Update the requestedItem for the selected product
    const updatedFormValues = {
      ...formValues,
      products: formValues?.products?.map((product) =>
        product.id === selectedProduct?.id
          ? {
              ...product,
              requestedItem: {
                product: product.id,
                requested_items: updatedRequestedItems,
                totalRequestedQuantity,
                // name: "muazam", // todo: remove this
              },
            }
          : product
      ),
    };

    setFormValues(updatedFormValues);
    localStorage.setItem("formValues", JSON.stringify(updatedFormValues));
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        sx={{
          width: "600px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "10px 10px 0 10px",
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "24px",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(false)}
          >
            <IoClose />
          </Box>
        </Box>
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              border: "1px solid #e0e0e0",
              padding: "15px",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                width: "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  sx={{
                    width: "150px",
                    fontWeight: "600",
                  }}
                >
                  Product Name:
                </Typography>
                <Typography>
                  {selectedProduct?.product?.commons?.en?.productName}
                </Typography>
              </Box>
              {/* 2nd */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  sx={{
                    width: "150px",
                    fontWeight: "600",
                  }}
                >
                  Category:
                </Typography>
                <Typography>
                  {selectedProduct?.product?.productCategory} /{" "}
                  {selectedProduct?.product?.productCategory}
                </Typography>
              </Box>
              {/* 3rd */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginBottom: "5px",
                }}
              >
                <Typography
                  sx={{
                    width: "150px",
                    fontWeight: "600",
                  }}
                >
                  Brand:
                </Typography>
                <Typography>{selectedProduct?.product?.brand}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "30%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={selectedProduct?.product.images[0].image}
                  alt="img"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "10px 20px 10px 15px",
            backgroundColor: "#ebf5ff",
            color: "#006bd6",
            borderRadius: "4px",
            margin: "5px 10px",
          }}
        >
          <Typography>
            These Products are found in the below WareHouses
          </Typography>
        </Box>

        <Box
          sx={{
            padding: "10px",
          }}
        >
          {/* Table Head */}
          <Box
            sx={{
              margin: "0 0 20px 0",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              background: "#4dbb6c",
              padding: "10px",
              color: "#fff",
              borderRadius: "4px",
              // justifyContent: "space-between",
              width: "100%",
              "@media (max-width: 576px)": {
                overflowX: "auto",
              },
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, width: "25%" }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "25%",
              }}
            >
              Location
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "25%",
              }}
            >
              Available Quantity
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                width: "25%",
              }}
            >
              Required Quantity
            </Typography>
          </Box>
          {/* Table Body */}
          {getWareHousesByProductLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MoonLoader color="#000" size={20} />
            </Box>
          ) : wareHouses?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                No Warehouses Found
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                You can add stock in the product listing page
              </Typography>
              {/* Link add */}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/dashboard/products-list")}
                sx={{
                  margin: "20px 0",
                  textTransform: "capitalize",
                }}
                startIcon={<FaAngleLeft />}
              >
                Go to Products Listing
              </Button>
            </Box>
          ) : (
            wareHouses?.map((item, index) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    padding: "10px",
                    borderBottom: "1px solid #e0e0e0",
                    width: "100%",
                    "@media (max-width: 576px)": {
                      overflowX: "auto",
                    },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", width: "25%" }}>
                    {item?.batchNumber}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", width: "25%" }}>
                    {item?.warehouse?.name} / {item?.warehouse?.location}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", width: "25%" }}>
                    {Number(item?.productQuantity)}
                  </Typography>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <PrimaryInput
                      size="small"
                      placeholder="Required Qty"
                      value={requestedItems[index]?.requestedQuantity || ""}
                      onChange={(e) => {
                        if (e.target.value > Number(item?.productQuantity)) {
                          e.target.value = item?.productQuantity;
                        }
                        handleRequestedQuantityChange(index, e.target.value);
                      }}
                      sx={{
                        width: "120px",
                      }}
                      height="40px"
                    />
                  </Box>
                </Box>
              );
            })
          )}

          <Box
            sx={{
              // marginTop: "10px",
              padding: "20px 15px 10px 10px",
              display: "flex",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: "120px",
              }}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: "120px",
              }}
              onClick={() => {
                localStorage.setItem("formValues", JSON.stringify(formValues));
                setModalOpen(false);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ProductWareHouseModal;
