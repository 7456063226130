import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// Loader Import
import { MoonLoader } from "react-spinners";

// MUI Components Import
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// Components Import
import Header from "../../components/Header";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { baseUrl } from "../../../../constants/api";
import { useFormik } from "formik";
import * as Yup from "yup";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import { SubHeading } from "../../../../components/Heading";
// Formik
import { Form, Formik } from "formik";
import { onKeyDown } from "../../../../utils";
import {
  useCreateWareHouseMutation,
  useDeleteWareHouseMutation,
  useUpdateWareHouseMutation,
} from "../../../../redux/api/wareHouseApiSlice";
import { useGetEntitiesQuery } from "../../../../redux/api/categoryApiSlice";
import CustomChip from "../../../../components/CustomChip";

const addWareHouseSchema = Yup.object().shape({
  warehouseName: Yup.string().required("Name is required"),
  location: Yup.string().required("Location is required"),
  // optional
  isActive: Yup.boolean(),
  isEcommerce: Yup.boolean(),
});

const headerData = {
  heading: "Configure Warehouses",
  subheading: "",
};

function Warehouses() {
  const [warehousesList, setWarehousesList] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [uuid, setUUID] = useState("");

  const [formValues, setFormValues] = useState({
    warehouseName: "",
    location: "",
    isActive: false,
    isEcommerce: false,
  });
  const [editValue, setEditValue] = useState("");
  const [deleteValue, setDeleteValue] = useState("");

  // todo: GET ENTITIES (WAREHOUSES) API CALL
  const {
    data: entitiesData,
    isLoading: entitiesLoading,
    refetch: refetchEntities,
  } = useGetEntitiesQuery({});

  useEffect(() => {
    if (entitiesData) {
      setWarehousesList(entitiesData.warehouses);
    }
  }, [entitiesData]);

  const resetFormValues = () => {
    setFormValues({
      ...formValues,
      warehouseName: "",
      location: "",
      isActive: false,
      isEcommerce: false,
    });
  };

  // todo: CREATE WAREHOUSE API BIND
  const [createWareHouse, { isLoading }] = useCreateWareHouseMutation();

  // todo: UPDATE WAREHOUSE API BIND
  const [updateWareHouse, { isLoading: updateWareHouseLoading }] =
    useUpdateWareHouseMutation();

  const AddWareHouseHandler = async (data, resetForm) => {
    const payload = {
      name: data.warehouseName,
      location: data.location,
      is_active: data.isActive,
      set_as_ecommerce_warehouse: data.isEcommerce,
      latitude: 0.0,
      longitude: 0.0,
    };

    // todo: UPDATE WAREHOUSE
    if (editValue) {
      try {
        const update = await updateWareHouse({
          body: payload,
          id: editValue?.uuid,
        });

        if (!update?.error) {
          toast.success("Warehouse updated successfully!");
          resetForm();
          resetFormValues();
          setEditValue("");
          refetchEntities();
        }
        if (update?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Warehouse Type Error:", error);
        toast.error(error.response.data.message);
      }

      return;
    }

    try {
      const create = await createWareHouse(payload);

      if (!create?.error) {
        toast.success("Warehouse created successfully!");
        resetForm();
        resetFormValues();
        refetchEntities();
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Warehouse Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const editWarehouse = (warehouses) => {
    setFormValues({
      ...formValues,
      warehouseName: warehouses.name,
      location: warehouses.location,
      isActive: warehouses.is_active,
      isEcommerce: warehouses.set_as_ecommerce_warehouse,
    });
    setEditValue(warehouses);
  };

  // todo: DELETE WAREHOUSE API BIND
  const [deleteWareHouse, { isLoading: deleteWareHouseLoading }] =
    useDeleteWareHouseMutation();

  const handleDelete = async (id) => {
    try {
      const deleteCharge = await deleteWareHouse(id);

      if (!deleteCharge?.error) {
        toast.success("Warehouse deleted successfully!");
        resetFormValues();
        refetchEntities();
      }
      if (deleteCharge?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Delete Warehouse Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const openConfirmationModal = (id) => {
    setUUID(id);
    setConfirmationModal(true);
  };

  return (
    <>
      {uuid ? (
        <ConfirmationModal
          prodId={uuid}
          open={confirmationModal}
          setOpen={setConfirmationModal}
          heading={"Confirm Warehouse Deletion"}
          text={"Are you sure you want to delete the Warehouse?"}
          handleDeleteProduct={handleDelete}
        />
      ) : null}

      <Wrapper maxWidth={false}>
        <Header data={headerData} />
        <ControlWrapper mt={3}>
          <Heading>Create a Warehouse</Heading>
          <Grid container mt={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <Formik
                initialValues={formValues}
                onSubmit={(values, { resetForm }) => {
                  AddWareHouseHandler(values, resetForm);
                }}
                validationSchema={addWareHouseSchema}
                enableReinitialize
              >
                {(props) => {
                  const { values, touched, errors, handleBlur, handleChange } =
                    props;

                  return (
                    <Form onKeyDown={onKeyDown} style={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Box sx={{ width: "100%", height: "75px" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Warehouse Name
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="warehouseName"
                            placeholder="Warehouse Name"
                            value={values.warehouseName}
                            helperText={
                              errors.warehouseName && touched.warehouseName
                                ? errors.warehouseName
                                : ""
                            }
                            error={
                              errors.warehouseName && touched.warehouseName
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // borderRadius={3}
                          />
                        </Box>

                        <Box sx={{ width: "100%", height: "75px" }}>
                          <SubHeading
                            sx={{ marginBottom: "5px", fontSize: "12px" }}
                          >
                            Warehouse Location
                          </SubHeading>
                          <PrimaryInput
                            type="text"
                            label=""
                            name="location"
                            placeholder="Warehouse Location"
                            value={values.location}
                            helperText={
                              errors.location && touched.location
                                ? errors.location
                                : ""
                            }
                            error={
                              errors.location && touched.location ? true : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // borderRadius={3}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Is Active"
                            name="isActive"
                            checked={values.isActive}
                            onChange={handleChange}
                          />

                          <FormControlLabel
                            control={<Checkbox />}
                            label="Set Ecommerce Warehouse"
                            name="isEcommerce"
                            checked={values.isEcommerce}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "15px",
                          gap: "20px",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{
                            height: "40px",
                            width: "150px",
                            mt: "23px",
                            borderRadius: "5px",
                          }}
                        >
                          {isLoading || updateWareHouseLoading ? (
                            <>
                              <MoonLoader color="#fff" size={20} />
                            </>
                          ) : editValue ? (
                            "Update"
                          ) : (
                            "Create"
                          )}
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </ControlWrapper>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>Location</TableHeadings>
                <TableHeadings>Is Active</TableHeadings>
                <TableHeadings>Ecommerce</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {entitiesLoading ? (
                <TableRow>
                  <TableContent colSpan={12} sx={{ width: "100%" }}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : warehousesList.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={12} align="center">
                    You do not have any Warehouse yet!
                  </TableContent>
                </TableRow>
              ) : (
                warehousesList?.map((warehouse, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>
                      {index + 1}
                    </TableContent>
                    <TableContent sx={{ width: "20%" }}>
                      {warehouse.name}
                    </TableContent>
                    <TableContent>{warehouse.location}</TableContent>
                    <TableContent>
                      <CustomChip label={warehouse?.is_active ? "Yes" : "No"} />
                    </TableContent>

                    <TableContent>
                      <CustomChip
                        label={
                          warehouse?.set_as_ecommerce_warehouse ? "Yes" : "No"
                        }
                      />
                    </TableContent>

                    <TableContent
                      sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editWarehouse(warehouse)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        {deleteWareHouseLoading &&
                        warehouse.uuid === deleteValue.uuid ? (
                          <MoonLoader color="#000" size={16} />
                        ) : (
                          <DeleteIcon
                            sx={{ fontSize: "20px", color: "red" }}
                            onClick={() => {
                              openConfirmationModal(warehouse?.uuid);
                              setDeleteValue(warehouse);
                            }}
                          />
                        )}
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "20px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Warehouses;
