import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Grid,
  FormControl,
  Button,
  Checkbox,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../../constants/api";
import Dropdown from "./Dropdown";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  backgroundColor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  gap: "12px",
};

function UploadCSVModal({ open, setOpen, fetchFiles }) {
  const token = window.localStorage.getItem("user-token");

  const inpUpload = useRef();

  const selectFile = () => {
    inpUpload.current.click();
  };

  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [cat, setCat] = useState("");
  const [subcat, setSubcat] = useState("");
  const [aiSuggestions, setAiSuggestionsTrue] = useState(false);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      });
      setCategoriesList(response.data.categories);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCatSubcats = (subcatId) => {
    axios
      .get(`${baseUrl}categories/${subcatId}/subcategories`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setSubcategoriesList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function uploadCSV(csvFile, filename) {
    const csvEndpoint = `${baseUrl}xlsx/upload/${cat}/${subcat}`; // Replace with your API endpoint
    const token = window.localStorage.getItem("user-token");

    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("file", csvFile, filename); // 'formFile' should match the server-side field name

      // Set headers, including Authorization
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      };

      // Send the POST request using axios
      const response = await axios.post(csvEndpoint, formData, { headers });

      if (response.status === 201) {
        return { status: true, message: response.data.message };
      } else {
        return { status: false, message: response.data.message };
      }
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }

  async function sendFile() {
    setUploading(true);
    const success = await uploadCSV(files, files.name);
    if (success.status) {
      toast.success(success.message);
      setFiles(null);
      setOpen(false);
      fetchFiles();
    } else {
      toast.error(success.message);
      // setModalOpen(false)
      setUploading(false);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            Upload XLSX File
          </Typography>
          <Wrapper>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Label>Category</Label>
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  mt: "5px",
                  background: "#fff",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Select
                  sx={{ borderRadius: "12px", width: "300px" }}
                  onChange={(e) => {
                    setCat(e.target.value);
                    const subcatId = categoriesList.find(
                      (item) => item.catId === e.target.value
                    ).id;
                    if (subcatId) {
                      fetchCatSubcats(subcatId);
                    }
                  }}
                  value={cat}
                >
                  {categoriesList?.map((item) => {
                    return (
                      <MenuItem key={item.catId} value={item.catId}>
                        {item.name} - {item.arb_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Label>Subcategory</Label>
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  mt: "5px",
                  background: "#fff",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Select
                  sx={{ borderRadius: "12px", width: "300px" }}
                  value={subcat}
                  onChange={(e) => setSubcat(e.target.value)}
                >
                  {subcategoriesList?.map((item) => {
                    return (
                      <MenuItem key={item.subId} value={item.subId}>
                        {item.name} - {item.arb_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Wrapper>
          <Grid container mt={3}>
            <Grid
              item
              sm={12}
              md={12}
              display={"flex"}
              alignItems={"center"}
              gap={"15px"}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<UploadIcon />}
                sx={{ textTransform: "capitalize" }}
              >
                Select File
                <input
                  type="file"
                  accept=".xlsx"
                  ref={inpUpload}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const selectedCsv = e.target.files[0];
                    if (selectedCsv) {
                      setFiles(selectedCsv);
                    }
                  }}
                />
              </Button>
              {files ? (
                <>
                  <Typography>Selected File:</Typography>
                  <Typography>{files.name}</Typography>
                </>
              ) : null}
            </Grid>
            {files ? (
              <Grid item md={12} mt={2} display={"flex"} alignItems={"center"}>
                <Checkbox
                  type="checkbox"
                  checked={aiSuggestions}
                  onChange={(e) => setAiSuggestionsTrue(e.target.checked)}
                />
                <Label>Integrate AI Based Suggestions</Label>
              </Grid>
            ) : null}
          </Grid>
          <Grid container mt={3}>
            <Grid
              item
              sm={12}
              md={12}
              display={"flex"}
              justifyContent={"end"}
              gap={"15px"}
            >
              <BottomButton
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </BottomButton>
              <BottomButton
                variant="contained"
                color="primary"
                onClick={() => sendFile()}
              >
                {uploading ? (
                  <>
                    <MoonLoader color="#fff" size={20} />
                  </>
                ) : (
                  "Upload File"
                )}
              </BottomButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const Wrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "start",
  gap: "30px",
}));

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
});

const BottomButton = styled(Button)(({ theme }) => ({
  height: "40px",
  width: "auto",
  padding: "0 20px",
  mt: "23px",
  borderRadius: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));

export default UploadCSVModal;
