import { createTheme } from "@mui/material";

const theme = createTheme({
	palette: {
		primary: {
			main: "#009444",
		},
		secondary: {
			main: "#009444",
		},
	},
	typography: {
		fontFamily:"'DM Sans', sans-serif"
	}
});

export default theme