import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Grid, Typography, FormControl, Select, MenuItem } from "@mui/material";

// Icons Import
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";

function Dropdown({
  label,
  size,
  options,
  onChange,
  onAddClick,
  value,
  notAdd,
}) {
  const handleAddClick = () => {
    onAddClick(label);
  };

  return (
    <>
      <Wrapper item md={size} my={2}>
        <Label>{label}</Label>
        <FormControl
          mt={3}
          size="small"
          sx={{
            background: "#fff",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Select
            value={value}
            onChange={onChange}
            sx={{ width: "100%", borderRadius: "12px" }}
          >
            {options?.map((item) => {
              return (
                <MenuItem
                  key={notAdd ? item.value : item.id}
                  value={notAdd ? item.value : item.id}
                >
                  {notAdd ? (
                    <>{item.name}</>
                  ) : (
                    <>
                      {item.name} - {item.arb_name}
                    </>
                  )}
                </MenuItem>
              );
            })}
          </Select>
          {notAdd ? (
            ""
          ) : (
            <AddIcon
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={handleAddClick}
            />
          )}
        </FormControl>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "Column",
}));

const Label = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
});

export default Dropdown;
