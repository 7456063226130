import React from "react";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  createFilterOptions,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const CustomAutocomplete = (props) => {
  const {
    formik,
    name,
    placeholder,
    readOnly = false,
    sx,
    borderRadius,
    background,
    multiline,
    labelKey = "lookup_name", // Default to 'lookup_name' if not provided
  } = props;

  return (
    <>
      <Autocomplete
        id="tags-outlined"
        fullWidth
        options={props.options || []}
        value={formik ? formik.values[name] || [] : props.value || []}
        onChange={(event, value) => {
          formik
            ? formik.setFieldValue(name, value)
            : props.onChange && props.onChange(value);
        }}
        getOptionLabel={(option) => option?.[labelKey] || ""} // Use specified label key
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option[labelKey], inputValue, {
            insideWords: true,
          });
          const parts = parse(option[labelKey], matches);
          return (
            <li {...props}>
              <div>
                {parts?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 800 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        sx={{
          fontWeight: "400",
          borderRadius: "5px",
          background: "#fff",
          border: "none",
          height: "50px !important",
          width: "100%",
          "& .MuiInputBase-root": {
            height: "50px !important",
          },
          color: (theme) => theme.palette.text.primary,
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
          },
          "& .Mui-disabled": {
            color: (theme) => theme.palette.text.disabled,
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "4.5px 4px 4.5px 5px !important",
          },
          "& .MuiAutocomplete-tag": {
            margin: "0 4px !important",
          },
          ...sx, // Spread provided custom styles
        }}
        placeholder="Search"
        multiple={props.multiple ?? true}
        filterSelectedOptions
        filterOptions={(options, state) => {
          const filteredOptions = options.filter((option) =>
            option?.[labelKey]
              ?.toLowerCase()
              .includes(state.inputValue.toLowerCase())
          );
          return filterOptions(filteredOptions, state);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder ?? ""}
            error={formik?.touched[name] && Boolean(formik?.errors[name])}
            onBlur={formik?.handleBlur}
            helperText={
              formik?.touched[name] && formik?.errors[name]
                ? formik?.errors[name]
                : ""
            }
            sx={[
              {
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "& .MuiFormHelperText-root": {
                  marginLeft: "2px !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
                "& .MuiOutlinedInput-input": {
                  cursor: readOnly ? "not-allowed" : "",
                },
              },
              sx,
            ]}
            variant={"outlined"}
            InputProps={{
              sx: {
                borderRadius: borderRadius ? borderRadius : "5px",
                background: background ? background : "#fff",
                height: multiline ? "auto" : "50px",
                border: "none",
              },
              readOnly: readOnly,
              ...params.InputProps,
              startAdornment: (
                <>
                  {props.startAndornment && (
                    <InputAdornment position="start">
                      {props.startAndornment}
                    </InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default CustomAutocomplete;
