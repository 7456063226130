import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

// MUI Component Import
import {
	Box,
	Container,
	Grid,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

// Components Import
import Header from "../../components/Header";
import UploadCSVCard from "../components/UploadCSVCard";
import DownloadCSVCard from "../components/DownloadCSVCard";

import axios from "axios";
import { baseUrl } from "../../../../constants/api";
import { useNavigate } from "react-router";
import MUITable, { StyledTableCell, StyledTableRow } from "../../../../components/MUITable";
import { FILES_PAGINATION_LIMIT } from "../../../../constants/constant";

const headerData = {
	heading: "Bulk Products Upload/Download",
	subheading: "We utilize excel files import services to efficiently upload your catalogue",
};

function BulkHandling() {
	const token = window.localStorage.getItem("user-token");

	const navigate = useNavigate()
	// eslint-disable-next-line no-unused-vars
	const [loading, isLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const fetchFiles = () => {
		isLoading(true);
		axios
			.get(`${baseUrl}files`, {
				params: {
					limit: FILES_PAGINATION_LIMIT,
					offset: page,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setFiles(response.data.results);
				setCount(response.data?.total_count);
				isLoading(false);
			})
			.catch((err) => {
				console.log(err);
				isLoading(false);
			});
	};

	useEffect(() => {
		fetchFiles();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

	const tableHead = [
		"#",
		"File Id",
		"File Name",
		"Status",
		// "Results",
	];

	return (
		<>
			<Wrapper maxWidth={false}>
				<Header data={headerData} />
				<CardsWrapper container my={3}>
					<DownloadCSVCard />
					<UploadCSVCard fetchFiles={fetchFiles} />
				</CardsWrapper>
				<Box
					sx={{
					margin: "10px 0",
					boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
					}}
				>
					<MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
						{files?.length > 0 ? (
							files?.map((file, index) => (
								<TableRow key={index}>
									<TableContent>{index + 1}</TableContent>
									<TableContent>{file?.pfId}</TableContent>
									<TableContent>{file?.file_name}</TableContent>
									<TableContent>{file?.status_display}</TableContent>
									{/* {file?.status_display === "Completed" ?
										<TableContent>
											<Typography sx={{ fontSize: "12px", textDecoration: "underline", cursor: "pointer" }}
												onClick={() => navigate('/dashboard/log-report', { state: { data: file?.reason } })}
											>
												View Log
											</Typography>
										</TableContent>
										: null
									} */}
								</TableRow>
							))
						) : (
							<StyledTableRow>
								<StyledTableCell
									sx={{ height: "100px" }}
									colSpan={tableHead?.length}
									align="center"
								>
									<Box
									sx={{
										fontSize: "18px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: 1,
									}}
									>
										{files?.length === 0 ? "No records found" : ""}
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</MUITable>
					<Box
						sx={{
							background: "#fff",
							display: "flex",
							justifyContent: "end",
							margin: "1px 0 0",
							padding: "15px 10px",
						}}
					>
						<Pagination
							count={Math.ceil(count / FILES_PAGINATION_LIMIT)}
							shape="rounded"
							onChange={handleChangePage}
						/>
					</Box>
				</Box>
			</Wrapper>
		</>
	);
}

// Styled Components

const Wrapper = styled(Container)(() => ({
	padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
	overflow: "auto",
}));

const CardsWrapper = styled(Grid)(() => ({
	height: "70%",
	padding: "2rem",
	borderRadius: "15px",
	gap: "40px",
	background: "#fff",
}));

const TableWrapper = styled(TableContainer)(() => ({
	height: "auto",
	overflow: "auto",
	border: "none",
	boxShadow: "none",
	margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
	fontWeight: "600",
	fontSize: "14px",
	lineHeight: "16px",
	color: "black",
	background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
	fontWeight: "400",
	fontSize: "12px",
	lineHeight: "16px",
	color: "#71747D",
	border: "none",
}));

export default BulkHandling;