import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { baseUrl } from "../../../../constants/api";
import {
  useChangeProductStatusMutation,
  useGetProductsQuery,
} from "../../../../redux/api/productsApiSlice";
import { PRODUCT_PAGINATION_LIMIT } from "../../../../constants/constant";
import axios from "axios";

// MUI Components import
import {
  Container,
  Select,
  Button,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Tooltip,
  InputBase,
  Pagination,
  Switch,
} from "@mui/material";

// Components Import
import Header from "../../components/Header";
import Loader from "../../../Loaders/Loader";
import LocationsModal from "../components/Modals/LocationsModal";
import StockModal from "../components/Modals/StockModal";
import VariantsModal from "../components/Modals/VariantsModal";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons Import
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import CustomChip from "../../../../components/CustomChip";
import { thousandSeparator } from "../../../../utils";
import { MoonLoader } from "react-spinners";

const STATUS = {
  1: {
    status: "Active",
    color: "#BCFFB6",
  },
  2: {
    status: "Inactive",
    color: "#FFD0B6",
  },
  3: {
    status: "Pending",
    color: "#FFF6A9",
  },
  4: {
    status: "Rejected",
    color: "#FF0000",
  },
  5: {
    status: "Approved",
    color: "#0000FF",
  },
  6: {
    status: "Out of Stock",
    color: "#f5f5f5",
  },
  7: {
    status: "Purchase in Process",
    color: "#f6f6f6",
  },
};

function ProductList({ serviceScreen }) {
  const token = window.localStorage.getItem("user-token");

  const navigate = useNavigate();
  const { handleLoader } = useLoaderContext();

  const [products, setProducts] = useState([]);
  const [productTypesList, setProductTypesList] = useState([]);
  const [locationsModal, setLocationsModal] = useState(false);
  const [stockModal, setStockModal] = useState(false);
  const [variantsModal, setVariantsModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [prodId, setProdId] = useState("");
  const [singleProduct, setSingleProduct] = useState({});
  const [brandsList, setBrandsList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productSubcategory, setProductSubcategory] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setproductBrand] = useState("");
  const [productModel, setProductModel] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [productType, setProductType] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  // todo: UPDATE PRODUCT STATUS API BIND
  const [updateProductStatus, { isLoading: updateProductStatusLoading }] =
    useChangeProductStatusMutation();

  const handlePublishedChange = async (e, row) => {
    try {
      const update = await updateProductStatus({
        productStatus: row?.productStatus === 1 ? 2 : 1,
        id: row?.prodId,
      });

      if (!update?.error) {
        toast.success("Product status updated successfully!");
      }
      if (update?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Product Status Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchProducts,
  } = useGetProductsQuery(
    {
      limit: PRODUCT_PAGINATION_LIMIT,
      offset: page,
      serviceScreen: serviceScreen,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      setCount(data?.total_count);
    }
  }, [data]);

  useEffect(() => {
    if (window.localStorage.getItem("products")) {
      refetchProducts();
      window.localStorage.removeItem("products");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.getItem("products")]);

  const headerData = {
    heading: serviceScreen ? "Services List" : "Products List",
    subheading: "",
  };

  const fetchProductList = async () => {
    setSearchTerm("");
    setProductModel("");
    setProductCategory("");
    setProductSubcategory("");
    setproductBrand("");
    setProductType("");
    handleLoader(true);

    const url = serviceScreen ? `${baseUrl}services` : `${baseUrl}products`;

    try {
      const response = await axios.get(url, {
        params: {
          limit: PRODUCT_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      setProducts(response.data?.results);

      setCount(response.data?.total_count);
    } catch (err) {
      console.log(err);
    } finally {
      handleLoader(false);
    }
  };

  const fetchEntities = async () => {
    try {
      const response = await axios.get(`${baseUrl}entities`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Token ${token}`,
        },
      });
      setBrandsList(response.data.brands);
      setModelsList(response.data.models);
      setCategoriesList(response.data.categories);
      setSubcategoriesList(response.data.subcategories);
      setProductTypesList(response.data.product_types);
    } catch (err) {
      console.log(err);
    }
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const handleEditProduct = (id) => {
    navigate(`/dashboard/edit-product/${id}`, {
      state: { serviceScreen: serviceScreen },
    });
  };

  const handleDeleteProduct = (id) => {
    handleLoader(true);
    axios
      .delete(`${baseUrl}product/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Product deleted successfully!");
        handleLoader(false);
        fetchProductList();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        handleLoader(false);
      });
  };

  const searchProduct = () => {
    handleLoader(true);
    axios
      .post(
        `${baseUrl}products`,
        {
          search_term: searchTerm,
        },
        {
          params: {
            limit: PRODUCT_PAGINATION_LIMIT,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setProducts(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const applyFilters = () => {
    handleLoader(true);
    let payload = {};
    // const order_Status = orderStatus;

    if (productSubcategory !== "") {
      payload.subId = productSubcategory;
    }
    if (productCategory !== "") {
      payload.cat_id = productCategory;
    }
    if (productBrand !== "") {
      payload.brandId = productBrand;
    }
    if (productModel !== "") {
      payload.modelId = productModel;
    }

    if (productType !== "") {
      payload.product_type = productType;
    }

    axios
      .post(`${baseUrl}products`, payload, {
        params: {
          limit: PRODUCT_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setProducts(response.data?.results);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openLocationsModal = (id) => {
    setUniqueId(id);
    setLocationsModal(true);
  };

  const openStockModal = (id) => {
    setProdId(id);
    setStockModal(true);
  };
  const openConfirmationModal = (id) => {
    setProdId(id);
    setConfirmationModal(true);
  };

  const openVariantsModal = (id, row) => {
    setProdId(id);
    setSingleProduct(row);
    setVariantsModal(true);
  };

  async function downloadCSV() {
    try {
      const response = await axios.get(`${baseUrl}products/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Token ${token}`,
        },
        responseType: "blob", // Specify response type as 'blob' to handle binary data
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "products.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("xlsx file downloaded successfully.");
      } else {
        toast.error("Failed to download xlsx file.");
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  useEffect(() => {
    if (data) {
      fetchEntities();
      setProducts(data.results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page]);

  const tableHead = [
    "Image",
    "Product",
    "Product Type",
    "Categories",
    "Brand",
    "Model",
    "Status",
    "Total Qty.",
    "Available Qty.",
    "Reserved Qty.",
    "Expired Qty.",
    "Locations",
    "Stock",
    "Variants",
    "Published",
    "Action",
  ];

  const getProductTypeName = (id) => {
    const ProductName = productTypesList.find((item) => item.value === id);
    return ProductName?.name;
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        {uniqueId ? (
          <LocationsModal
            id={uniqueId}
            open={locationsModal}
            setOpen={setLocationsModal}
          />
        ) : null}
        {prodId && StockModal ? (
          <StockModal
            prodId={prodId}
            open={stockModal}
            setOpen={setStockModal}
            fetchProductList={fetchProductList}
          />
        ) : null}
        {prodId && VariantsModal ? (
          <VariantsModal
            singleProduct={singleProduct}
            open={variantsModal}
            setOpen={setVariantsModal}
          />
        ) : null}
        {prodId && ConfirmationModal ? (
          <ConfirmationModal
            prodId={prodId}
            open={confirmationModal}
            setOpen={setConfirmationModal}
            heading={"Confirm Product Deletion"}
            text={"Are you sure you want to delete the product?"}
            handleDeleteProduct={handleDeleteProduct}
          />
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header data={headerData} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {!serviceScreen && (
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                color="secondary"
                backgroundcolor={"green"}
                startIcon={<DownloadIcon />}
                onClick={() => downloadCSV()}
              >
                Download XLXS
              </Button>
            )}
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="secondary"
              backgroundcolor={"green"}
              startIcon={<AddIcon />}
              onClick={() =>
                navigate("/dashboard/add-product", {
                  state: { serviceScreen: serviceScreen },
                })
              }
            >
              {serviceScreen ? "Add Service" : "Add Product"}
            </Button>
          </Box>
        </Box>

        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by name"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchProduct()}>Search</Button>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={"12px"}
            width={"100%"}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="product-brand-label">Brand</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="product-brand-label"
                id="product-brand-select"
                value={productBrand}
                label="Product Brand"
                onChange={(e) => setproductBrand(e.target.value)}
              >
                {brandsList?.map((item) => {
                  return (
                    <MenuItem key={item.brandId} value={item.brandId}>
                      {item.name} - {item.arb_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="product-model-label">Model</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="product-model-label"
                id="product-model-select"
                value={productModel}
                label="Product Model"
                onChange={(e) => setProductModel(e.target.value)}
              >
                {modelsList?.map((item) => {
                  return (
                    <MenuItem key={item.modelId} value={item.modelId}>
                      {item.name} - {item.arb_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Dropdown>

            <Dropdown size="small" fullWidth>
              <InputLabel id="product-type-label">Product Type</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="product-type-label"
                id="product-type-select"
                value={productType}
                label="Product Subcategory"
                onChange={(e) => setProductType(e.target.value)}
              >
                {productTypesList?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Dropdown>

            <Dropdown size="small" fullWidth>
              <InputLabel id="product-category-label">Category</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="product-category-label"
                id="product-category-select"
                value={productCategory}
                label="Product Category"
                onChange={(e) => setProductCategory(e.target.value)}
              >
                {categoriesList?.map((item) => {
                  return (
                    <MenuItem key={item.catId} value={item.catId}>
                      {item.name} - {item.arb_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Dropdown>

            <Dropdown size="small" fullWidth>
              <InputLabel id="product-subcategory-label">
                SubCategory
              </InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="product-subcategory-label"
                id="product-subcategory-select"
                value={productSubcategory}
                label="Product Subcategory"
                onChange={(e) => setProductSubcategory(e.target.value)}
              >
                {subcategoriesList?.map((item) => {
                  return (
                    <MenuItem key={item.subId} value={item.subId}>
                      {item.name} - {item.arb_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Dropdown>

            <Button onClick={() => applyFilters()}>Apply</Button>
            <Button onClick={() => fetchProductList()}>Reset</Button>
          </Box>
        </SearchNav>

        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
              {isSuccess && products?.length > 0 ? (
                products?.map((row) => {
                  return (
                    <StyledTableRow key={row?.id}>
                      <StyledTableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          <img
                            src={row?.images[0]?.image}
                            alt="product_image"
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "5px",
                              marginRight: "8px",
                              objectFit: "fill",
                            }}
                          />
                        </div>
                      </StyledTableCell>

                      <StyledTableCell>
                        {truncate(row?.commons?.en?.productName, 40)} ,{" "}
                        {truncate(row?.commons?.ar?.productName, 40)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getProductTypeName(row?.productType)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.productCategory} / {row?.productSubcategory}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getProductTypeName(row?.productType) === "Service"
                          ? "-"
                          : row?.brand}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box>
                          {getProductTypeName(row?.productType) === "Service"
                            ? "-"
                            : row?.model}
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Box>
                          <CustomChip
                            label={STATUS[row?.productStatus]["status"]}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {getProductTypeName(row?.productType) === "Service"
                          ? "-"
                          : thousandSeparator(Number(row?.total_quantity))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getProductTypeName(row?.productType) === "Service"
                          ? "-"
                          : thousandSeparator(Number(row?.available_quantity))}
                      </StyledTableCell>

                      <StyledTableCell>
                        {getProductTypeName(row?.productType) === "Service"
                          ? "-"
                          : thousandSeparator(Number(row?.reserved_qty))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getProductTypeName(row?.productType) === "Service"
                          ? "-"
                          : thousandSeparator(
                              Number(row?.total_expired_stocks_quantity)
                            )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          disabled={
                            getProductTypeName(row?.productType) === "Service"
                          }
                          onClick={() => openLocationsModal(row?.prodId)}
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            fontWeight: "300",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                        >
                          See Locations
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          disabled={
                            getProductTypeName(row?.productType) === "Service"
                          }
                          onClick={() => openStockModal(row?.prodId)}
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            fontWeight: "300",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<AddIcon fontSize="10px" />}
                        >
                          Manage
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          disabled={
                            getProductTypeName(row?.productType) === "Service"
                          }
                          onClick={() => openVariantsModal(row?.prodId, row)}
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            fontWeight: "300",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<AddIcon fontSize="10px" />}
                        >
                          Manage
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        {selectedProduct === row?.prodId &&
                        updateProductStatusLoading ? (
                          <Box
                            sx={{
                              marginLeft: "20px",
                            }}
                          >
                            <MoonLoader color="gray" size={20} />
                          </Box>
                        ) : (
                          <Switch
                            size="medium"
                            color="secondary"
                            checked={
                              row?.productStatus === 1 ? true : false || false
                            }
                            onChange={(e) => {
                              handlePublishedChange(e, row);
                              setSelectedProduct(row?.prodId);
                            }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "8px",
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <EditIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleEditProduct(row?.prodId)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <DeleteIcon
                              sx={{ cursor: "pointer", color: "red" }}
                              onClick={() => openConfirmationModal(row?.prodId)}
                            />
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {products?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ fontSize: "14px", marginRight: "10px" }}>
                  Total Records: {count}
                </Box>
              </Box>
              <Pagination
                count={Math.ceil(count / PRODUCT_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

export default ProductList;
